import axios from 'axios'

const baseURL = process.env.VUE_APP_PROMOTORES_API
const token   = process.env.VUE_APP_PROMOTORES_API_TOKEN

const http = axios.create({ baseURL })

http.interceptors.request.use((config) => {
    config.headers['x-request-token'] = token

    return config
});
http.interceptors.response.use(({ data }) => data)

export default http;