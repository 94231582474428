<template>
  <div id="app">
    <Header />
    <div class="content">
      <Home />
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from './components/layout/Header.vue';
import Home from './views/Home.vue';
import Footer from './components/layout/Footer.vue';


export default {
  name: 'App',
  components: {
    Header,
    Home,
    Footer
  }
}
</script>

<style lang="scss">
  @import './assets/styles/main.scss';
</style>